<template>
  <container-page>
    <bloc-simple class="p-3 mb-3 rounded" style="min-width: 300px">
      <h5>Management de la qualité</h5>
      <div>
        <router-link
          to="/qsser/ncs"
          class="mt-1 cursor-pointer"
          :active-class="activeClass"
          :class="hoverClass"
          tag="div"
          >Ecarts</router-link
        >
        <router-link
          to="/qsser/evenements"
          class="mt-1 cursor-pointer"
          :active-class="activeClass"
          :class="hoverClass"
          tag="div"
          >Evènements</router-link
        >
        <router-link
          to="/qsser/animations"
          class="mt-1 cursor-pointer"
          :active-class="activeClass"
          :class="hoverClass"
          tag="div"
          >Animations</router-link
        >
        <router-link
          to="/qsser/visites"
          class="mt-1 cursor-pointer"
          :active-class="activeClass"
          :class="hoverClass"
          tag="div"
          >Visites</router-link
        >
        <router-link
          to="/qsser/actions"
          class="mt-1 cursor-pointer"
          :active-class="activeClass"
          :class="hoverClass"
          tag="div"
          >Actions</router-link
        >
      </div>
    </bloc-simple>
    <div>
      <div class="d-flex align-items-center" v-if="loading">
        <loader class="m-5"></loader>
        <h2 class="m-0">Chargement...</h2>
      </div>
    </div>
    <div class="flex-grow-1" v-if="!loading">
      <router-view></router-view>
    </div>
  </container-page>
</template>
<script>
import { mapActions } from "vuex";
import ContainerPage from "@/components/containers/ContainerPage.vue";
import BlocSimple from "@/components/containers/ContainerBloc.vue";
import Loader from "@/components/bases/Loader.vue";

export default {
  components: {
    ContainerPage,
    BlocSimple,
    Loader,
  },
  data() {
    return { loading: false };
  },
  computed: {
    activeClass: function () {
      if (!this.$store.getters["isDarkMode"]) {
        return "stroke-red text-danger";
      } else {
        return "stroke-red text-primary";
      }
    },
    hoverClass: function () {
      if (!this.$store.getters["isDarkMode"]) {
        return "cursor-hover-light";
      } else {
        return "cursor-hover-dark";
      }
    },
  },
  methods: {
    ...mapActions({
      getQsser: "qsser/getQsser",
      getData: "qsser/getData",
      getAffaires: "affaires/getAffaires",
      getActions: "qsser/getActions",
    }),
  },
  mounted() {
    this.loading = true;
    //this.getQsser().finally(() => (this.loading = false));
    this.getData().finally(() => (this.loading = false));
    this.getAffaires();
    this.getActions();
  },
};
</script>
<style lang="css"></style>
